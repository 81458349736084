//Have to force the colour for the custom export button to override the highchartcss
$primary-main-color: #00148c;

.highcharts-button-box + text {
	stroke: $primary-main-color !important;
	stroke-width: 0.3;
}

.highcharts-button-box {
	stroke: $primary-main-color !important;
	border-radius: 16px;
}
