@import "leaflet/dist/leaflet.css";
@import "react-leaflet-markercluster/dist/styles.min.css";

:root {
	--map-tiles-filter: brightness(0.7) invert(1) contrast(4) hue-rotate(200deg) saturate(0.7) brightness(0.7);
}

.theme-dark {
	.leaflet-container {
		background: #121518;
		outline: 0;
	}
	.leaflet-tile-pane {
		filter: var(--map-tiles-filter, none);
	}
	.leaflet-control-zoom-in,
	.leaflet-control-zoom-out {
		background-color: #181c20;
		color: rgba(255, 255, 255, 0.7);
	}
	.leaflet-control-zoom-in:hover,
	.leaflet-control-zoom-out:hover {
		background-color: #212529;
		color: #fff;
	}
	.leaflet-bar a:first-child {
		border-bottom: 1px solid rgba(255, 255, 255, 0.12);
	}
	.leaflet-control-attribution {
		background-color: #181c20;
		color: rgba(255, 255, 255, 0.7);
	}
}

.leaflet-popup-content {
	margin: 0px;
	width: auto !important;
}

.leaflet-popup-content-wrapper {
	background: none;
	padding: 0px;
}
